.input {
  border-radius: rem-calc(4);
  outline: none;  
  height: rem-calc(38);
  padding: rem-calc(8 12);
  font: {
    size: rem-calc(14);
    family: $RobotoMedium;
  }
  display: block;
  width: 100%;
  border: 1px solid $grey-EA;

  &::placeholder {
    color: $grey-8C;
    font: {
      family: $RobotoRegular;
      weight: 300;
    }
  }

  &-blue {
    background-color: $white;
    color: $blue-darken;
    border: none;
    height: rem-calc(50);
    padding: rem-calc(12 20);

    &::placeholder {
      color: rgba($blue-darken, .6);
    }
  }

  &.ng-invalid.ng-touched{
    color: $red;
  
    &::placeholder {
      color: $red;
    }
  }

  &-group {
    label {
      display: inline-block;
      color: $grey-40;
      font: {
        size: rem-calc(14);
        family: $RobotoMedium;
      }
      margin-bottom: rem-calc(6);
    }
  }
}