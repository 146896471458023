$RobotoRegular: RobotoRegular, "Helvetica Neue", sans-serif;
$RobotoMedium: RobotoMedium, "Helvetica Neue", sans-serif;
$RobotoBold: RobotoBold, "Helvetica Neue", sans-serif;

$Futura: Futura, "Helvetica Neue", sans-serif;

$font-thin: 100;
$font-extralight: 200;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-extrabold: 800;
$font-black: 900;
