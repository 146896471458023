@import 'basic';

$inputHeight: rem-calc(40);
$inputHeightPhone: rem-calc(38);
$inputBorderColor: $blue;
$inputBorder: 1px solid $inputBorderColor;
$inputBorderRadius: rem-calc(50);
$inputFontSize: rem-calc(13);
$inputFontSizePhone: rem-calc(16);

.ng-select {
  &-container {
    height: $inputHeightPhone !important;
    min-height: $inputHeightPhone !important;
    border: $inputBorder !important;
    border-radius: $inputBorderRadius !important;
    
    @include media-breakpoint-up(md) {
      height: $inputHeight !important;
      min-height: $inputHeight !important;
    }

    .ng-value {
      font-size: $inputFontSizePhone;
      color: $blue-darken;
      font-family: $RobotoMedium;

      @include media-breakpoint-up(md) {
        font-size: $inputFontSize;
      }

      &-container {
        padding-left: rem-calc(15);

        .ng-input {
          padding-left: rem-calc(15);
          top: auto !important;
        }

        .ng-placeholder {
          color: $grey-73;
          font: {
            size: $inputFontSizePhone;
            weight: 300;
          }

          @include media-breakpoint-up(md) {
            font-size: $inputFontSize;
          }
        }
      }
    }

    .ng-clear {
      &-wrapper {
        width: rem-calc(10);
        margin-left: rem-calc(8);
      }
    }
  }

  .ng-select-container {
    .ng-value-container {
      padding-left: rem-calc(12);

      .ng-placeholder {
        color: $grey-8C;

        font: {
          size: rem-calc(14);
          family: $RobotoRegular;
          weight: 300;
        }
      }
    }
  }

  &.ng-select-multiple {
    .ng-select-container {
      .ng-value-container {
        .ng-placeholder {
          top: rem-calc(7);
        }
      }
    }
  }

  &.ng-select-opened {
    & > .ng-select-container {
      .ng-arrow {
        top: rem-calc(4);
        transform: rotate(-135deg);
      }
    }
  }

  &-container {
    .ng-arrow {
      width: rem-calc(7) !important;
      height: rem-calc(7) !important;
      border: solid $blue-darken !important;
      border-width: 0 2px 2px 0 !important;
      margin-top: -1px;
      transform: rotate(45deg);
  
      &-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: rem-calc(20);
        height: rem-calc(25);
        padding: 0;
        margin: {
          right: rem-calc(5);
          top: rem-calc(-2);
        }
      }
    }
  }

  .ng-dropdown-panel {
    border-color: $inputBorderColor;
    width: 100%;
    margin-left: 0;
    max-width: rem-calc(450);
    margin-top: rem-calc(4);
    border: 1px solid $grey-EA;
    box-shadow: rem-calc(0 8 16) rgba($black, 0.1);
    border-radius: rem-calc(4);
  
    &-items {
      .ng-option {
        font-size: rem-calc(12);
        padding: rem-calc(6 10);
        margin: rem-calc(2 4);
        color: $blue-darken;
       
        &:not(:last-child) {
          border-bottom: 1px solid $grey-f11;
        }

        &,.ng-option-disabled {
          color: $grey-8C;
        }

        &.ng-option-marked {
          color: $white;
          background: $blue-darken;
          border-radius: rem-calc(4);
          margin: rem-calc(2);
          border-color: transparent;
        }

        &.ng-option-selected {
          border-radius: rem-calc(4);
          margin: rem-calc(2);
          border-color: transparent;
        }
      }
    }
  }

  &.ng-select-rounded {
    .ng-select {
      &-container {
        border-radius: rem-calc(100) !important;
      }
    }


    .ng-select-container {
      .ng-value-container {
        padding-left: rem-calc(22);

        .ng-input {
          padding-left: rem-calc(22);
        }

        .ng-value {
          color: $grey-A6;
          font-size: rem-calc(14);
        }
      }
    }

    .ng-dropdown-panel {
      border-radius: rem-calc(20);
      margin-top: rem-calc(4);
      width: 100%;
      margin-left: 0;
  
      &-items {
        .ng-option {
          font-size: rem-calc(14);
          padding: rem-calc(10 16);
          margin: rem-calc(4);
          border-bottom: none;
          color: $blue-darken;
          font-family: $RobotoMedium;

          &.ng-option-marked:not(.ng-option-selected) {
            color: $white;
          }
        
          &, &:last-child {
            border-radius: rem-calc(30);
          }

          &.ng-option-marked, &.ng-option-selected {
            margin: rem-calc(4);
          }
        }
      }
    }
  }
}