$black: #000000;
$white: #ffffff;

$blue: #003060;
$blue-dark: #002040;
$blue-dark2: #0040C1;
$blue-vivid: #0073E5;
$blue-medium: #4160B0;
$blue-darken: #002D5F;

$cyan-pale: #acd2db;
$cyan-soft: #4DA3B1;
$cyan-green: #357690;

$pink: #CF4178;

$grey-40: #404040;
$grey-73: #737373;
$grey-8C: #8C8C8C;
$grey-A6: #A6A6A6;
$grey-BC: #BCBCBC;
$grey-B2: #B2B2B2;
$grey-C6: #C6C6C6;
$grey-CC: #CCCCCC;
$grey-D9: #D9D9D9;
$grey-EA: #EAEAEA;
$grey-EB: #EBEBEB;
$grey-E5: #E5E5E5;
$grey-E9: #E9E9E9;
$grey-F0: #f0f0f0;
$grey-f2: #f2f2f2;
$grey-f5: #f5f5f5;
$grey-f7: #f7f7f7;
$grey-f8: #f8f8f8;
$grey-f9: #f9f5ff;
$grey-f10: #E6E6E6;
$grey-f11: #EFEFEF;

$red: #f33c23;

$yellow: #FDB022;
