@import '../mixins/grid';
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

// Loop that create margin/padding helpers to manage spacing between elements
$spaceamounts: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 24, 25, 28, 30, 32, 35, 36, 40, 45, 50, 55, 60, 65, 70);
$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
  .m-#{$space} {
    margin: #{$space}px !important;
  }

  .p-#{$space} {
    padding: #{$space}px !important;
  }

  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{rem-calc($space)} !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{rem-calc($space)} !important;
    }
  }
}

.col-xl-20 {
  @include media-breakpoint-up(xl) {
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }
}

.d {
  &-block {
    display: block !important;
  }

  &-inline-block {
    display: inline-block !important;
  }

  &-flex {
    display: flex !important;
  }

  &-inline-flex {
    display: inline-flex !important;
  }
}

.text {
  &-center {
    text-align: center !important;
  }

  &-right {
    text-align: right !important;
  }

  &-left {
    text-align: left !important;
  }

  &-uppercase {
    text-transform: uppercase !important;
  }
}

.font {
  &-color {
    &-black {
      color: $black;
    }
    &-white {
      color: $white;
    }
  }

  &-weight {
    &-thin {
      font-weight: $font-thin;
    }
    &-extralight {
      font-weight: $font-extralight;
    }
    &-light {
      font-weight: $font-light;
    }
    &-regular {
      font-weight: $font-regular;
    }
    &-medium {
      font-weight: $font-medium;
    }
    &-semibold {
      font-weight: $font-semibold;
    }
    &-bold {
      font-weight: $font-bold;
    }
    &-extrabold {
      font-weight: $font-extrabold;
    }
    &-black {
      font-weight: $font-black;
    }
  }
}
