@import 'basic';

.owl-carousel {
  .owl {
    &-nav {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: rem-calc(8);
      margin-top: rem-calc(30);
    }

    &-prev, &-next {
      position: relative;
      width: rem-calc(50);
      height: rem-calc(50);
      border-radius: 50%;
      border: rem-calc(1) solid $blue;
      background-color: transparent;
      font-size: 0;
      
      &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        color: $blue;
        font: {
          size: rem-calc(28);
          family: 'Material Icons';
        }
      }

      &.disabled {
        opacity: .3;
      }
    }

    &-prev {
      &:before {
        content: 'chevron_left'
      }
    }

    &-next {
      &:before {
        content: 'chevron_right'
      }
    }
  }
}
