.button {
  background-color: transparent;
  border: 0;
  cursor: pointer;

  &:disabled {
    pointer-events: none;
    opacity: .8;
  }

  &-filled,
  &-outlined,
  &-transparent {
    height: rem-calc(30);
    line-height: rem-calc(30) !important;

    font: {
      family: $RobotoMedium;
      size: rem-calc(12);
      weight: $font-medium;
    }

    ;

    &__icon {
      height: rem-calc(20) !important;
      width: rem-calc(20) !important;
      font-size: rem-calc(20);
    }
  }

  &-filled {
    color: $white;
    background-color: $blue-darken;

    .icon {
      display: inline-flex;
      align-items: center;
      margin: rem-calc(0 18);
      width: rem-calc(12);
      height: rem-calc(12);

      svg {
        height: rem-calc(14);
      }

      path {
        fill: $white;
      }
    }
  }

  &-default {
    font-size: rem-calc(16);
    height: rem-calc(38);
    padding: rem-calc(0 24);
    border-radius: rem-calc(4);
    min-width: rem-calc(120);
  }

  &-with-icon {
    display: flex;
    align-items: center;
    padding: rem-calc(0 24 0 0);
  }

  &-outlined {
    color: $grey-73;
    background-color: $white;
    border: rem-calc(1) solid $grey-EA  !important;
  }

  &-transparent {
    color: $black;
    background-color: transparent;
  }

  &-submit {
    color: $white;
    background-color: $blue-vivid;
  }
}
