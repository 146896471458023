@import '../basic';

* {
  box-sizing: border-box;
  //outline: 1px dotted blue;
}

html, body {
  height: 100%;
}

body {
  color: $blue;
  font: {
    family: $RobotoRegular;
    size: rem-calc(16);
    weight: $font-regular;
  };
  margin: 0;
  padding: 0;
}

hr {
  border: none;
  border-top: 1px solid $grey-E5;
  margin: rem-calc(60 0);
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

p {
  line-height: 1.6;
}

a {
  color: inherit;
}

h1 {
  font: {
    family: $Futura;
    size: rem-calc(36);
    weight: $font-regular;
  }
}

h2 {
  font: {
    family: $Futura;
    size: rem-calc(20);
    weight: $font-regular;
  }
}

h3 {
  font: {
    family: $Futura;
    size: rem-calc(16);
    weight: $font-medium;
  }
}

h4 {
  font: {
    family: $Futura;
    size: rem-calc(14);
    weight: $font-regular;
  }
}

h5 {
  font: {
    family: $Futura;
    size: rem-calc(14);
    weight: $font-regular;
  }
}

strong {
  font-family: $RobotoBold;
}

input,
input[type='text'],
input[type='password'],
input[type='email'],
input[type='time'],
textarea {
  font-size: rem-calc(16);

  @include media-breakpoint-up(md) {
    font-size: rem-calc(14);
  }
}
