@font-face {
  src: url("../../fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-family: RobotoRegular;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  src: url("../../fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-family: RobotoMedium;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  src: url("../../fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-family: RobotoBold;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  src: url("../../fonts/Futura/FUTURAPTBOLD.OTF") format("opentype");
  font-family: Futura;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  src: url("../../fonts/Futura/FUTURAPTMEDIUM.OTF") format("opentype");
  font-family: Futura;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}
