
.pagination {
  display: flex;
  justify-content: center;
  font: {
    family: $RobotoMedium;
    size: rem-calc(12);
  }
  padding-top: rem-calc(36);
  margin-bottom: rem-calc(1);

  &__page {
    width: rem-calc(34);
    height: rem-calc(34);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    border-radius: rem-calc(6);
    margin: rem-calc(0 4);
    cursor: pointer;
    font-family: $Futura;

    &:hover, &_active {
      background-color: $blue-darken;
      color: $white;
    }
  }

  &__space {
    width: rem-calc(34);
    height: rem-calc(34);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: rem-calc(0 4);
    color: $blue-darken;
  }

  .pagination__button {
    width: rem-calc(34);
    height: rem-calc(34);
    min-width: rem-calc(34);
    line-height: rem-calc(28);
    border-radius: rem-calc(6) !important;
    padding: 0;
    margin-top: 0;
    background-color: transparent;
    color: $blue-dark;

    .mat-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.mat-button-disabled {
      opacity: .1;
    }

    &--previous {
      border-top-left-radius: rem-calc(2);
      border-bottom-left-radius: rem-calc(2);
      margin-right: rem-calc(12);
    }

    &--next {
      margin-left: rem-calc(-1);
      border-top-right-radius: rem-calc(2);
      border-bottom-right-radius: rem-calc(2);
      margin-left: rem-calc(12);
    }
  }
}
