@import './animations';
@import '../functions/rem-calc';
@import '../variables/colors';

.mat-spinner {
  margin: rem-calc(30) auto;

  &.white-color {
    svg {
      circle {
        stroke: $white;
      }
    }
  }

  svg {
    circle {
      stroke: $blue;
    }
  }
}

.mat-checkbox.mat-accent {
  margin: rem-calc(3 0);

  .mat-ripple-element {
    background: $blue-dark2  !important;
  }

  .mat-checkbox {
    &-label {
      width: 100%;
      color: $black;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-layout {
      width: 100%;
    }
  
    &-inner-container {
      width: rem-calc(18);
      height: rem-calc(18);
      margin-left: 0;
    }
  
    &-frame, &-background {
      border-radius: rem-calc(5);
    }
  
    &-frame {
      border: rem-calc(1) solid $grey-CC !important;
    }
  }

  &.mat-checkbox-checked {
    .mat-checkbox {
      &-background {
        background: $grey-f9;
        border: rem-calc(1) solid $blue-dark2;
      }
  
      &-checkmark-path {
        stroke: $blue-dark2 !important;
      }
    }
  }
}

.mat-radio-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: rem-calc(10 0);

  .mat-radio-button {
    &:not(:last-child) {
      margin-bottom: rem-calc(10);
    }

    &.mat-accent {
      &.mat-radio-checked {
        .mat-radio-outer-circle {
          border-color: $blue-vivid;
        }

        .mat-radio-inner-circle {
          background-color: $blue-vivid;
        }
      }
    }

    .mat-ripple-element {
      background: $blue-vivid  !important;
    }
  }
}

.cdk-overlay-container {
  .cdk-overlay-dark-backdrop {
    background: rgba($blue, .8);
  }

  .cdk-overlay-pane {
    &.animate-slideInRight {
      animation: slideInRight .3s ease;
    }
  }

  .mat-dialog-container {
    padding: 0;
    box-shadow: rem-calc(0 5 6 0) rgba($black, .05);
  }
}

.mat-accordion.custom {

  .mat-expansion-panel,
  .mat-expanded {
    margin-bottom: rem-calc(10);
  }

  .mat-expansion-panel {
    border-radius: rem-calc(4) !important;
    box-shadow: none;
    border-left: rem-calc(4) solid transparent;
    transition: border-color .2s ease .1s;

    &.mat-expanded {
      border-left-color: $blue-vivid;
    }

    &-header {
      padding: rem-calc(10 0 10 10);
      height: auto;

      @include media-breakpoint-up(md) {
        padding-left: rem-calc(30);
      }

      &:hover {
        background: inherit !important;
      }

      .mat-content {
        font-size: rem-calc(14);
        font-weight: 500;
        padding: rem-calc(10 0);
        border-right: 1px solid $grey-F0;

        @include media-breakpoint-up(md) {
          padding: rem-calc(20 0);
          font-size: rem-calc(16);
        }
      }
    }

    &-body {
      padding: rem-calc(0 10);

      @include media-breakpoint-up(md) {
        padding: rem-calc(0 30 25 30);
      }
    }

    .mat-expansion-indicator {
      padding: rem-calc(20 30);
    }
  }
}

.mat-menu-panel {
  min-height: auto !important;
  border-radius: rem-calc(6) !important;

  .mat-menu-content {
    padding: rem-calc(4) !important;

    .mat-menu-item {
      height: rem-calc(36);
      line-height: rem-calc(36);
      color: $blue-darken;
      padding: 0 rem-calc(12);
      border-radius: rem-calc(6);

      font: {
        family: $RobotoMedium;
        size: rem-calc(12);
      };
    }
  }
}

.mat-stroked-button {
  line-height: rem-calc(40) !important;
  padding: rem-calc(0 20) !important;
  border: {
    radius: rem-calc(100) !important;
    color: $blue !important;
  }
}

.mat-flat-button {
  &.pink {
    background-color: $pink;
    color: $white;
  }
}

.mat-button {
  &-wrapper {
    display: flex;
    align-items: center;
  }
}
